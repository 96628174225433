<template>
  <v-container :fluid="$vuetify.breakpoint.mobile">
    <h2>Devenir bénévoles</h2>
    <v-row align="center" justify="center">
      <v-col cols="12" class="text-center">
        <v-img
            alt="Photo Bénévoles"
            contain
            class="ma-auto"
            src="../../assets/image/ludinam-benevoles.jpg"
            transition="scale-transition"
            max-width="800px"
        />
      </v-col>
    </v-row>
    <v-col cols="12" v-html="text">
    </v-col>
    <iframe
        :src="url"
        v-if="url"
        width="100%"
        height="1000px">Chargement en cours...
    </iframe>
  </v-container>
</template>

<script>
import GlobalParamsService from "../../services/global_params.service";

export default {
  name: "Benevole",
  data: () => ({
    url:null,
    text:null
  }),
  created() {
    GlobalParamsService.getInscriptionBenevoles().then(response => {
      this.url = response['data'].value.url;
      this.text = response['data'].value.text;
    });
  }
};
</script>

<style lang="scss" scoped>
</style>
